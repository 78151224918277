import highlight from "../helpers/results-highlighter";
import { Order } from "../types/order";

export default function BuyersSellers(props: { order: Order, searchText: string }) {
    const { order, searchText } = props;

    const buyers = [1, 2, 3, 4].map(i => {
        const firstName = order[`buyerFirstName${i}`];
        const lastName = order[`buyerLastName${i}`];

        if (firstName || lastName)
            return highlight(`${lastName}${lastName && ', '}${firstName}; `, searchText)
        return false;
    }).filter(b => !!b);;

    const hasBuyers = buyers && buyers.length > 0;

    const sellers = [1, 2, 3, 4].map(i => {
        const firstName = order[`sellerFirstName${i}`];
        const lastName = order[`sellerLastName${i}`];

        if (firstName || lastName)
            return highlight(`${lastName}${lastName && ', '}${firstName}; `, searchText)
        return false;
    }).filter(s => !!s);

    const hasSellers = sellers && sellers.length > 0;


    return (<>
        {hasBuyers && (buyers.length === 1 ? "Buyer: " : "Buyers: ")}
        {buyers}
        {hasSellers && <br />}
        {hasSellers && (sellers.length === 1 ? "Seller: " : "Sellers: ")}
        {sellers}
    </>
    )
}