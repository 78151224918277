// eslint-disable-next-line
export default {
    authConfig: {
      clientId: process.env.REACT_APP_AAD_CLIENT_ID,
      authority: process.env.REACT_APP_AAD_AUTHORITY,
      redirectUrl: process.env.REACT_APP_AAD_REDIRECT_URL,

    },
    apiUrl: process.env.REACT_APP_API_URL,
    supportEmail: process.env.REACT_APP_SUPPORT_EMAIL,
    blobServiceUrl: process.env.REACT_APP_BLOBS_URL,
    generateSas: process.env.REACT_APP_SAS_URL,
    isProduction: process.env.REACT_APP_PROD || false,
    containerName: process.env.REACT_APP_CONTAINER_NAME
};