import axios from "axios";
import { useEffect, useState } from "react";
import { useQueryParam, StringParam } from 'use-query-params';
import OrderDeatils from "../types/orderDetails";
import Loading from "./Loading";
import NotFound from "./NotFound";
import { format } from 'date-fns';

import "../ui/orderInfo.css"
import Documents from "./Documents";
import { currencyFormat } from "../helpers/formatter";


export default function OrderInfo() {
    const [id] = useQueryParam('id', StringParam);
    const [order, setOrder] = useState<OrderDeatils>(null);
    const [loading, setLoading] = useState<boolean>(true);


    useEffect(() => {
        const get = async () => {
            const { data } = await axios.get(`/Order/${id}`);
            setLoading(false);
            setOrder(data);
        }

        get();
    }, [id])



    if (loading)
        return (<Loading />);

    if (!order)
        return (<NotFound />)
    return (
        <article style={{ marginTop: '24px;' }}>
            <header>
                <h1 id="order-number-header">{order.orderInfo.orderNumber}</h1>
                {/* <a href="#" className="printInfo" data-bind="click: function(){ print() }">Print</a> */}
            </header>

            <div id="dvOrderInfo">
                {/* <!-- CONTACT INORMATION --> */}

                <div className="categoryHead" style={{ marginBottom: "16px;" }}>
                    <span>Contact Information</span>
                </div>
                {order.parties.map(contact => (
                    contact.lastName &&
                    <div className="contInfo">
                        <div className="contInfoOuter">
                            <div className="infoTitle">{contact.partyType}
                            </div>
                            <div className="contInfoInner">
                                <div className="infoTitle contInfoName">{`${contact.firstName} ${contact.middleName} ${contact.lastName}`}
                                </div>
                                <div className="contInfoText">
                                    <span>{contact.address}</span>
                                </div>
                            </div>
                        </div>
                    </div>))}
                {/* <!-- BASIC INORMATION --> */}
                <div className="categoryHead">

                    <span>Basic Information</span>
                </div>
                <div className="basicInfo" data-bind="with: order">
                    { /*<div className="basicInfoOuter">
                    <div className="basicInfoTitle">
                        <span className="infoTitle">Title #</span>
                    </div>
                    <div className="basicInfoText">
                        <span data-bind="text: orderNumber"></span>
                    </div>
                </div>*/ }
                    { /*<div className="basicInfoOuter">
                    <div className="basicInfoTitle">
                        Loan #
                    </div>
                    <div className="basicInfoText">
                        <span data-bind="text: loanNumber"></span>
                    </div>
                </div>*/ }
                    <div className="basicInfoOuter">
                        <div className="basicInfoTitle">
                            Transaction Type
                        </div>
                        <div className="basicInfoText">
                            <span>{order.orderInfo.transactionType}</span>
                        </div>
                    </div>
                    <div className="basicInfoOuter">
                        <div className="basicInfoTitle">
                            Purchase Price
                        </div>
                        <div className="basicInfoText">
                            <span>{currencyFormat(order.orderInfo.salesPrice)}</span>
                        </div>
                    </div>
                    <div className="basicInfoOuter">
                        <div className="basicInfoTitle">
                            Order Open Date
                        </div>
                        <div className="basicInfoText">
                            <span>{format(new Date(order.orderInfo.orderOpenDate), 'MM/dd/yyy')}</span>

                        </div>
                    </div>
                    <div className="basicInfoOuter">
                        <div className="basicInfoTitle">
                            Document Type
                        </div>
                        <div className="basicInfoText">
                            <span>{order.orderInfo.documentType}</span>
                        </div>
                    </div>
                    <div className="basicInfoOuter">
                        <div className="basicInfoTitle">
                            Underwriter Name
                        </div>
                        <div className="basicInfoText">
                            <span>{order.orderInfo.underwriterName}</span>

                        </div>
                    </div>
                    <div className="basicInfoOuter">
                        <div className="basicInfoTitle">
                            Order Close Date
                        </div>
                        <div className="basicInfoText">
                            <span>{format(new Date(order.orderInfo.orderCloseDate), 'MM/dd/yyy')}</span>
                        </div>
                    </div>
                </div>

                {/* <!-- PROPERTY INORMATION --> */}

                <div className="categoryHead">
                    <span>Property Information</span>
                </div>
                <div className="propInfo" data-bind="foreach: properties">

                    <div className="propInfoOuter">
                        <div className="propInfoTitle">
                            <span className="infoTitle">Property Address </span>
                        </div>
                        <div className="propInfoText">
                            <div className="propInfoTextL">
                                <span className="propInfoTextRtext">{`${order.parcelInfo.streetNumber} ${order.parcelInfo.streetName},`}</span>
                                <span className="propInfoTextRtext">{`${order.parcelInfo.city}, ${order.parcelInfo.state}, ${order.parcelInfo.postalCode}`}</span>
                            </div>
                            <div className="propInfoTextR">
                                <span className="infoTitle">County</span>
                                <span className="propInfoTextRtext">{order.parcelInfo.countyName}</span>
                                <span className="infoTitle">Block</span>
                                <span className="propInfoTextRtext">{order.parcelInfo.block}</span>
                                <span className="infoTitle">Lot</span>
                                <span className="propInfoTextRtext">{order.parcelInfo.lot}</span>
                            </div>
                        </div>
                    </div>
                </div>


                {/* <!-- Policy INORMATION --> */}
                {order.policyInfo && //order.policyInfo.insuredName &&
                    <>
                        <div className="categoryHead">

                            <span>Policy Information</span>
                        </div>
                        <div className="basicInfo">
                            <div className="basicInfoOuter">
                                <div className="basicInfoTitle">
                                    Insured Name
                                </div>
                                <div className="basicInfoText">
                                    <span>{order.policyInfo.insuredName}</span>
                                </div>
                            </div>
                            <div className="basicInfoOuter">
                                <div className="basicInfoTitle">
                                    Loan #
                                </div>
                                <div className="basicInfoText">
                                    <span>{order.policyInfo.loanNumber}</span>
                                </div>
                            </div>
                            <div className="basicInfoOuter">
                                <div className="basicInfoTitle">
                                    Effective Date & Time
                                </div>
                                <div className="basicInfoText">
                                    <span>{format(new Date(order.policyInfo.effectiveDateTime), 'MM/dd/yyy hh:mm a')}</span>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {/* <!-- Title INORMATION --> */}
                {order.titleInfo && order.titleInfo.titleCompanyName &&
                    <>
                        <div className="categoryHead">
                            <span>Title Information</span>
                        </div>
                        <div className="basicInfo titleInfo">
                            <div className="basicInfoOuter">
                                <div className="basicInfoTitle">
                                    Title Company Name
                                </div>
                                <div className="basicInfoText">
                                    <span>{order.titleInfo.titleCompanyName}</span>
                                </div>
                            </div>
                            <div className="basicInfoOuter">
                                <div className="basicInfoTitle">
                                    Title Close Date Date
                                </div>
                                <div className="basicInfoText">
                                    <span>{format(new Date(order.titleInfo.titleCloseDate), 'MM/dd/yyy')}</span>
                                </div>
                            </div>
                        </div>
                    </>
                }

                <Documents orderNumber={order.orderInfo.orderNumber} />
            </div>

        </article>

    )
}