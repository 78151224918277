import { useEffect, useState } from "react";
import DownloadIcon from '@mui/icons-material/CloudDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { saveAs } from 'file-saver';
import Loading from "./Loading";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Grid } from "@mui/material";
import JSZip from "jszip";
import useBlobService from "../helpers/blob-storage";



export default function Documents({ orderNumber }: any) {

    const [documents, setDocuments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState([]);

    const getClient = useBlobService();



    useEffect(() => {
        const get = async () => {

            if (!documents.length) {
                const client = await getClient();
                const response = client.listBlobsByHierarchy('/', {
                    prefix: `${orderNumber}/`,
                    includeMetadata: true
                });

                const files = [];
                for await (const item of response) {
                    if (!item.name.endsWith(".xml"))
                        files.push(item);
                }

                setDocuments(files);
                setLoading(false);
            }
        }
        get();

    }, [orderNumber, documents, getClient])

    const preview = async (doc: any) => {
        const client = await getClient();
        const blobClient = client.getBlobClient(doc.name);
        await blobClient.setHTTPHeaders({ blobContentDisposition: "inline" })

        const element = document.createElement('a');
        element.href = blobClient.url;
        element.target = '_blank';
        element.click();
    }

    const downloadZip = async () => {
        const zip = new JSZip();
        setLoading(true);

        const client = await getClient();

        const zippedFiles = selected.map(async fileName => {
            const response = await client.getBlobClient(fileName).download();
            const blob = await response.blobBody
            return zip.file(fileName.replace(`${orderNumber}/`, ''), blob);
        });

        await Promise.all(zippedFiles);

        zip.generateAsync({ type: "blob" }).then((content) => {
            saveAs(content, `${orderNumber}.zip`);
            setLoading(false);
        });

    }

    const download = async (doc: any) => {
        setLoading(true);
        const client = await getClient();
        const url = client.getBlobClient(doc.name).url;
        saveAs(url, doc.name.replace(`${orderNumber}/`, ''))
        setLoading(false);
    }

    return (<>
        <div className="categoryHead">
            <span>Documents</span>
        </div>
        <div style={{ height: 600, width: '100%', position: 'relative' }}>
            {loading &&
                <div style={{ top: '100px', position: 'absolute', width: '100%' }}>
                    <Loading />
                </div>}
            {documents.length > 0 &&
                <DataGrid rows={documents} columns={[
                    {
                        field: "name",
                        headerName: "File name",
                        width: window.innerWidth * 0.83333 - 250,
                        renderCell: ({ row }: any) => <a onClick={() => preview(row)} href="#!">{row.name.replace(`${orderNumber}/`, '')}</a>
                    },
                    {
                        field: "download",
                        headerName: "Download",
                        renderCell: ({ row }: any) => <Grid container justifyContent="center">
                            <DownloadIcon onClick={() => download(row)} />
                        </Grid>
                    },
                    {
                        field: "preview",
                        headerName: "Preview",
                        renderCell: ({ row }: any) => <Grid container justifyContent="center">
                            <VisibilityIcon onClick={() => preview(row)} />
                        </Grid>
                    }
                ]} checkboxSelection={true}
                    getRowId={({ name }: any) => name}
                    onSelectionModelChange={setSelected}
                    components={{
                        Toolbar: () => (<Grid container justifyContent="flex-start">
                            <Button variant="outlined" onClick={downloadZip} disabled={selected.length === 0}>Download</Button>
                        </Grid>)
                    }}
                />
            }
        </div>
    </>);
}