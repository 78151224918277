import { BlobServiceClient } from "@azure/storage-blob";
import axios from "axios";
import { useCallback } from "react";
import config from "../config";

const containerName = config.containerName;

const useBlobService = () => {
  const generateSas = async () => {
    const { data } = await axios.post(config.generateSas, { "name": containerName }, { baseURL: "" });
    return data;
  }


  const expired = (sas: string) => {
    if(!sas) 
      return true;

    const params = new URLSearchParams(sas)
    const sasExpiration = new Date(params.get('se') || '')
    return (
      !sasExpiration ||
      Math.round(
        (new Date(sasExpiration).getTime() - new Date().getTime()) / 60000
      ) < 10
    )
  }

  const getSas = useCallback(async () => {
    let sas = localStorage.getItem(`${containerName}-sas`)
    if (expired(sas)) {
      sas = await generateSas();
      localStorage.setItem(`${containerName}-sas`, sas);
    }
    return sas;
  }, []);

  const getClient = useCallback(async () => {
    const sas = await getSas()
    if (sas) {
      const service = new BlobServiceClient(`${config.blobServiceUrl}/?${sas}`);
      return service.getContainerClient(containerName);
    }
  }, [getSas]);

  return getClient
}

export default useBlobService;