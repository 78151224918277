import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import { useAuth } from "../context/Auth";
import LoginButton from "./LoginButton";

const useStyles = makeStyles((theme) => ({
  appBar: {
    height: "50vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  submit: {
    borderColor: theme.palette.common.white,
    padding: theme.spacing(1, 4),
    marginTop: theme.spacing(3),
  },
}));

export default function Login({ children }: any) {
  let location = useLocation();

  const classes = useStyles();

  const { isAuth } = useAuth();

  const { from } = (location.state as any) || { from: { pathname: "/" } };

  // if we have a user, we shouldn't be at this url
  if (isAuth) return <Redirect to={from} />;

  return (
    <Box textAlign="center" position="static" className={classes.appBar}>
      <Typography component="h1" variant="h2">
        {children}{" "}
      </Typography>
      <LoginButton />
    </Box>
  );
}
