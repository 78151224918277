import NoResults from '../ui/icons/NoResults';
import config from '../config';

const supportEmail  = config.supportEmail;


export default function NotFound() {
    return  <div className='tac'>
    <h1 className='mt10 mb3'>No results found</h1>
    <p className='mb6'>Sorry about that. If you believe this is an error, please email <a href={`mailto:${supportEmail}`}>{supportEmail}</a></p>
    <NoResults />
  </div>
}
