import React, { useState, useEffect } from 'react';
import axios, { AxiosResponse } from 'axios';
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from '@mui/icons-material/Search';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';

import { Order } from '../types/order';
import useDebounce from '../hooks/use-debounce';
import { addToHistory, getAllFromHistory } from '../helpers/order-history';
import OrdersTable from './OrdersTable';

import { useQueryParam, StringParam } from 'use-query-params';
import NotFound from './NotFound';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: 32,
    paddingTop: 32,
  },
  container: {
    maxWidth: 1440,
  },
  search: {
    position: 'relative',
    marginLeft: 0,
    width: '100%',
    height: 56,
    '& input': { width: '100%', maxWidth: '100%' },
  },
  searchIcon: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    pointerEvents: 'none',
    position: 'absolute',
    width: theme.spacing(7),
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
    '& input': { padding: theme.spacing(2, 1, 2, 7) },
    '& .MuiFilledInput-root': { backgroundColor: 'rgba(0, 0, 0, 0.03)' },
    '& .MuiFilledInput-underline:after': { borderBottom: '2px solid black' },
  },
  viewOrders: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  indicator: {
    backgroundColor: 'black',
    fontWeight: 'bold',
  },
  tabPanel: {
    border: '1px solid red',
  },
  tabRoot: {
    minHeight: '48px',
  },
}));

export default function Home() {
  const classes = useStyles({});
  const [orders, setOrders] = useState<Order[]>([]);
  const orderHistory = getAllFromHistory();
  const [isSearching, setIsSearching] = useState(false);
  const [query, setQuery] = useQueryParam('query', StringParam);
  const [searchText, setSearchText] = useState(query);
  const debouncedSearchTerm = useDebounce(searchText, 500);

  const buildDocumentPageLink = (orderNumber: string) => `/documents?id=${orderNumber}`;

  const goToOrder = (order: Order) => {
    addToHistory(order);
    let a = document.createElement('a');
    a.href = buildDocumentPageLink(order.rootId);
    a.click();
  };

  const onSearchKeyPress = async (e: { keyCode: number }) => {
    setIsSearching(true);
    const goToOrderNoHistory = (orderNumber: string) => {
      let a = document.createElement('a');
      a.href = buildDocumentPageLink(orderNumber);
      a.click();
    };

    if (e.keyCode !== 13 || !searchText) {
      return;
    }

    const { data: orders }: AxiosResponse<Order[]> = await axios.get(`/Search/search?query=${searchText}`);
    if (!orders.length) {
      goToOrderNoHistory(searchText);
      return;
    }
    const order = orders.find((o) => o.orderNumber.toLowerCase() === searchText.trim().toLowerCase());
    if (!order) {
      return;
    }
    goToOrder(order);
    setIsSearching(false);
  };

  useEffect(() => {
    const doSearch = async () => {
      setIsSearching(true);
      if (debouncedSearchTerm) {
        const orderPromise = axios.get(`/Search/search?query=${debouncedSearchTerm}`);
        const { data: orders } = await orderPromise;
        setOrders(orders);
      } else {
        setOrders([]);
      }
      setIsSearching(false);
    };

    doSearch();

    setQuery(debouncedSearchTerm, 'replaceIn');
  }, [debouncedSearchTerm, setQuery]);

  return (
    <Container className={classes.container}>
      <div className={classes.root}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <TextField
            autoFocus
            id='filled-basic'
            placeholder='Search for file number, contact name or address'
            variant='filled'
            onKeyDown={onSearchKeyPress}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className={classes.inputRoot}
          />
        </div>
      </div>
      <div>
        {!!orders.length && searchText && (
          <>
            <OrdersTable
              orders={orders}
              searchText={debouncedSearchTerm}
              onOrderClick={addToHistory}
              headerText='Search Results'
            />
          </>
        )}
      </div>
      {debouncedSearchTerm && searchText && !orders.length && !isSearching && (
        <NotFound />
      )}
      <div>
        {!!orderHistory.length && !searchText && (
          <div>
            <OrdersTable orders={orderHistory} onOrderClick={addToHistory} headerText='Recent Orders' />
          </div>
        )}
      </div>
    </Container>
  );
};
