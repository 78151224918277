import React, { Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { AuthContextProvider } from "./context/Auth";
import Loading from "./components/Loading";
import Logout from "./components/Logout";
import Login from "./components/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import Page from "./pages/Page";
import Home from "./components/Home";
import OrderInfo from "./components/OrderInfo";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import theme from "./ui/theme";



declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}



function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
      <AuthContextProvider>
        <BrowserRouter>
          <Suspense fallback={<Loading />}>
            <Switch>
              <ProtectedRoute exact path="/">
                <Page><Home/></Page>
              </ProtectedRoute>
              <ProtectedRoute path="/documents">
                <Page><OrderInfo /></Page>
              </ProtectedRoute>
              <Route path="/login">
                <Login>OTS - Online Title Search</Login>
              </Route>
              <Route path="/logout">
                <Logout />
              </Route>
            </Switch>
          </Suspense>
        </BrowserRouter>
      </AuthContextProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
