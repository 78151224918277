import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { OrdersTableProps } from '../types/prop-types';
import { format } from 'date-fns';
import highlight from '../helpers/results-highlighter';
import { Order } from '../types/order';
import BuyersSellers from './BuyersSellers';

const useToolbarStyles = makeStyles(theme => ({
  title: {
    fontWeight: 700,
    fontSize: 20,
    padding: '16px 24px',
  },
  tableHeader: {
    '& th, span': { fontWeight: 700, fontSize: 14 },
  },
  orderNumberCell: {
    maxWidth: 150,
    '& p': { fontSize: 14 },
  },
  rowRoot: {
    height: 73,
    cursor: 'pointer',
    '& td': { padding: '0 16px' },
  },
  textOverFlow: {
    maxHeight: 41,
    overflow: 'auto',
    paddingRight: 4,
  },
}));
 //Order Number | Close Date | Address | County | Block/Lot/Parcel | Buyer/Seller


export default function OrderTable({ orders, searchText, onOrderClick, headerText }: OrdersTableProps){
  const classes = useToolbarStyles({});
  return (
    <TableContainer component={Paper} elevation={0}>
      <Typography className={classes.title} variant='h6' id='tableTitle'>
        {headerText}
      </Typography>
      <Table>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell align='left'>
              <Typography noWrap component='span'>
                Order Number
              </Typography>
            </TableCell>
            <TableCell align='left'>
              <Typography noWrap component='span'>
                Closing Date
              </Typography>
            </TableCell>
            <TableCell align='left'>Address</TableCell>
            <TableCell align='left'>County</TableCell>
            <TableCell align='left'>Block/Lot/Parcel</TableCell>
            
            <TableCell align='left'>Buyer/Seller</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order: Order, i) => {
            let blockLot = '';
            if(order.block) {
              blockLot = `Block: ${order.block}, `;
              if(order.lot) {
                blockLot += `Lot: ${order.lot}`;
              } 
              // else if(order.parcel) {
              //   blockLot += `Parcel: ${order.parcel}`;
              // }
            } else {
              // blockLot = order.escrowBriefLegal;
            }
            //@ts-ignore
            return <TableRow component="a" href={`/documents?id=${order.rootId}`} classes={{ root: classes.rowRoot }} hover key={i} onClick={() => onOrderClick(order)}>
              <TableCell align='left' className={classes.orderNumberCell}>
                <Typography noWrap>{highlight(order.orderNumber, searchText)}</Typography>
              </TableCell>
              <TableCell align='left'>
                {order.closeDate ? format(new Date(order.closeDate), 'MM/dd/yyy') : 'Open'}
              </TableCell>
              <TableCell align='left'>
                {highlight(`${order.address} ${order.city || ''} ${order.state || ''}`, searchText)}
                {/* {!!order.additionalPropertyAddresses && <br />}
                {highlight(order.additionalPropertyAddresses?.join(', '), searchText)} */}
                </TableCell>
              
              <TableCell align='left'>{highlight(order.county, searchText)}</TableCell>
              <TableCell align='left'>{highlight(blockLot, searchText)}</TableCell>
              <TableCell align='left'>
                <div style={{minWidth:400}}>
                  <BuyersSellers order={order} searchText={searchText} />
                  </div>
              </TableCell>
             
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
