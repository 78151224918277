import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';


const theme = createTheme(adaptV4Theme({
  palette: {
    primary: {
      main: grey[900],
    },
    text: {
      primary: '#000',
    },
  },
  // status: {
  //   danger: 'orange',
  // },
  typography: {
    // fontWeight: 900,
    fontSize: 14,
    fontFamily: [
      'Red Hat Text',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '-apple-system',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
        },
      },
    },
  },
}));

export default theme;
