import React from 'react'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles';
import {Backdrop, Card, CardContent} from '@mui/material'
import LoginButton from './LoginButton'

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    overflow: 'auto'
  },
  backdrop: {
    position: 'absolute',
    zIndex: theme.zIndex.modal + 1
  }
}))

export default function LoginPopup() {
  const classes = useStyles()

  return (
    <Backdrop open={true} className={classes.backdrop}>
      <Card variant="outlined" elevation={4} className={classes.card}>
        <CardContent>
          <Box justifyContent="space-around">
            <Box>Your session in ended.</Box>
          </Box>
          <LoginButton />
        </CardContent>
      </Card>
    </Backdrop>
  )
}
