import * as React from 'react';
const Highlighter = require("react-highlight-words");

const highlight = (text: string, searchText?: string, onlyExactMatch: boolean = false) => {
  if (!text || !searchText) {
    return text;
  }
  if(onlyExactMatch && text.toLocaleLowerCase().trim() !== searchText.toLocaleLowerCase().trim()) {
    return text;
  }
  return <Highlighter
    searchWords={searchText.replaceAll("\"", "").split(' ')}
    autoEscape={true}
    textToHighlight={text}
  />
};

export default highlight;