import * as React from "react";
import { QueryParamProvider } from "use-query-params";
import HeaderNavbar from "../components/HeaderNavbar";
import MainContainer from "../components/MainContainer";

const Page = ({ children }) => {
  return (
    <QueryParamProvider>
        <HeaderNavbar title="OTS - Online Title Search" />
        <MainContainer>{children}</MainContainer>
    </QueryParamProvider>
  );
};

export default Page;
