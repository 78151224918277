import React, {useEffect} from 'react'
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Avatar, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {useAuth} from '../context/Auth'
import {grey} from '@mui/material/colors'

const useStyles = makeStyles(theme => ({
  appBar: {
    borderBottom: '1px solid ' + grey[300],
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(4),
    height: theme.spacing(4),
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 'inherit'
  },
  helpIcon: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5)
  }
}))
interface HeaderNavbarProps {
  title?: string
}

export default function HeaderNavbar({title}: HeaderNavbarProps) {
  const classes = useStyles()
  const {user, logout} = useAuth()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const onLogoutClick = () => {
    logout()
    setAnchorEl(null)
  }

  const onAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const onCloseMenu = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    document.title = `${title}`
  }, [title])
  return (
    <AppBar position="fixed" elevation={0} className={classes.appBar}>
      <Toolbar>
        <Box flexGrow={1}>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <div>
          <Box display="flex" alignItems="center">
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={onAvatarClick}
              color="inherit"
              size="large">
              <Avatar className={classes.avatar}>
                {user?.givenName?.charAt(0)}
                {user?.surname?.charAt(0)}
              </Avatar>
            </IconButton>
          </Box>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            open={open}
            onClose={onCloseMenu}
          >
            <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}
