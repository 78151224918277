import React from 'react'
import {Route, Redirect, RouteProps} from 'react-router'
import {useAuth} from '../context/Auth'
import LoginPopup from './LoginPopup'

interface ProtectedRouteProps extends RouteProps {
  loginPopup?: boolean
}

export default function ProtectedRoute({
  children,
  loginPopup,
  ...rest
}: ProtectedRouteProps) {
  const {isAuth, isMsalAuth, shouldLogout} = useAuth()

  const components = children ? (
    <Route {...rest}>{children}</Route>
  ) : (
    <Route {...rest} />
  )

  if (shouldLogout && loginPopup)
    return (
      <>
        {components}
        <LoginPopup />
      </>
    )

  if (isAuth || isMsalAuth) {
    return components
  }

  return (
    <Redirect
      to={{
        pathname: '/login',
        state: {from: rest.location}
      }}
    />
  )
}
